
import { defineComponent } from 'vue'
import axios from 'axios';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'DisplayProducts',
    data() {
        return {
            quantity: 1,
            cartbtn: 'Add to cart',
            buttonDisabled: false,
            menu_choice: '',
            userStatus: false,
            state: '',
        }
    },
    props: {
        product_name: String,
        price: Number,
        product_id: String,
        photo: String,
        description: String,
        slug: String,
    },
    methods: {
        getMenuChoice() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'Search') {
                this.menu_choice = 'Search'
            }
        },
        addToCart() {
        const token = localStorage.getItem('AuthToken');
        
        this.cartbtn = 'Adding to cart...'
        this.buttonDisabled = true;

        axios.post('/api/carts/add_to_cart/', {product_id: this.product_id, quantity: this.quantity},
        {
          headers: {
                    Authorization: `JWT ${token}`,
                },
        }
        )
        .then(response => {
          console.log('Product added to cart:', response.data);
          this.cartbtn = 'Added'
          this.buttonDisabled = false;
        })
        .catch(error => {
          console.error('Error adding product to cart:', error);
          this.cartbtn = 'Buy now'
          this.buttonDisabled = false;
        });
    },
    checkUserStatus() {
        const token = localStorage.getItem('AuthToken');

        if (token) {
            this.userStatus = true;
        }
    },
    },
    watch: {
        $route(to: { name: string; }, from: any) {
            if (to.name === 'Search') {
                this.menu_choice = 'Search'
            }
        }
    },
    created() {
        this.getMenuChoice();
        this.checkUserStatus();
    },
})
