
import { useHelpCenterStore } from "@/store/help-center";
import axios from "axios";
import { defineComponent } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineComponent({
  name: "Footer",
  data() {
    return {
      privacy_policy: "",
      tandc: "",
      email: "",
      subBtn: "Subscribe",
      iconStyles: {
        fill: "#4385f5",
      },
    };
  },
  setup() {
    const helpCenterStore = useHelpCenterStore();
    return {
      helpCenterStore,
    };
  },
  methods: {
    subScribe() {
      this.subBtn = "Subscribing...";
      var formdata = new FormData();
      formdata.append("email", this.email);
      const id = toast.loading("subscribing to newsletter...", {
        position: toast.POSITION.TOP_RIGHT,
      });

      axios
        .post("/manager/news_letter/", formdata)
        .then((response) => {
          this.subBtn = "Subscribed";
          toast.update(id, {
            render: "Subscribed to newsletter",
            type: "success",
            isLoading: false,
          });
          console.log("Subscribed!", response);
        })
        .catch((error) => {
          this.subBtn = "Error subscribing";
          toast.update(id, {
            render: "Error subscribing",
            type: "error",
            isLoading: false,
          });
          console.error("Error subscribing:", error);
        })
        .finally(() => {
          setTimeout(() => toast.remove(id), 1000);
          this.email = "";
        });
    },
    async initHelpCenterData() {
      if (!this.helpCenterStore.helpCenterData) {
        try {
          this.helpCenterStore.fetchHelpCenterData();
          this.helpCenterStore.fetchSocials();
        } catch (error) {
          toast.error("Error occured");
        }
      }
    },
  },
  computed: {
    loadingLinks() {
      return this.helpCenterStore.fetching;
    },
  },
  created() {
    this.initHelpCenterData();
  },
});
