
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    name: 'MainHeader',
    props: {
        main_selected_header: {
            type: String,
            required: true
        },
        main_subtext_header:{
            type: String,
            required: true
        },
        main_list_header: {
            type: Array as PropType<String[]>,
            required: true
        },
        selectedItem : String,
    },
    methods: {
        selectItem(item: String) {
            this.$emit('item-selected', item);
        },
    },
})
