
import { useUserStore } from "@/store/user";
import { defineComponent } from "vue";
// import logo from '../assets/images/icons/logonew.png'

export default defineComponent({
  name: "Header",
  data() {
    return {
      user_logged_in: false,
      showMenu: false,
      Status: "Sign in",
      // sections: [] as string[],
    };
  },
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  methods: {
    // loadSections() {
    //   axios
    //     .get("/api/sections")
    //     .then((response) => {
    //       const sections = response.data;
    //       this.sections = sections.map(
    //         (section: { name: string }) => section.name
    //       );
    //       // This method below is to get their categories. Will come in handy later!
    //       //                this.categoryNames = data.reduce((acc, section) => {
    //       //                section.categories.forEach((category) => {
    //       //                    acc.push(category.name);
    //       //                });
    //       //                return acc;
    //       //                }, []);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching sections:", error);
    //     });
    // },
    showMenuFunc() {
      if (this.showMenu === false || this.showMenu === true) {
        this.showMenu = !this.showMenu;
        console.log(this.showMenu);
        // if (this.showMenu === false) {
        //   document.body.style.overflow = "auto";
        // } else {
        //   document.body.style.overflow = "hidden";
        // }
      }
    },
    setMenuFalse() {
      this.showMenu = false;
    },
    checkUserSignedIn() {
      const token = localStorage.getItem("AuthToken");

      if (token) {
        this.Status = "Log out";
      } else {
        this.Status = "Sign in";
      }
    },
    async logUserOut() {
      await this.userStore.signOut();
      window.location.href = "/";
    },
    goToSearch() {
      this.$router.push("/search");
    },
  },
  //    computed: {
  //        lowercaseSections() {
  //            return this.sections.map(section => section.toLowerCase());
  //        }
  //    },
  created() {
    this.checkUserSignedIn();
    // this.loadSections();
  },
});
