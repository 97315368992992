
import { defineComponent } from 'vue';
import axios from 'axios'
import {Product} from '@/router/types'
import HelpCentreFAQ from '@/components/MenuSidebar/HelpCentre.vue'

export default defineComponent({
    name: 'Search',
    components: {
        HelpCentreFAQ
    },
    data() {
        return {
            Search: [] as Product[],
        }
    },
    methods: {
        getSearch() {
            axios.get('/manager/faqs/')
            .then(response => {
                this.Search = response.data
            })
            .catch(error => {
                console.error('Error retrieving FAQs:', error)
            })
        },
    },
    mounted() {
        this.getSearch();
    },
})
