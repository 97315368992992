
import { defineComponent } from "vue";
import 'boxicons'

export default defineComponent ({
    name: 'HelpCentreFAQ',
    data() {
        return {
            expanded: false,
        }
    },
    props: {
        main_text: String,
        text_expanded: String,
    //    expanded: Boolean,
    },
    methods: {
        toggleExpanded() {
            this.expanded = !this.expanded;
        }
    }
})
