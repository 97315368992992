
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

export default defineComponent({
    name: 'Address',
    data () {
        return {
            settings: true,
            country: '-',
            stateCity: '-',
            postalCode: '-',
            taxID: '-',
            errors: {
                detail: "I'm an error",
            },
            inputDisabled: true,
        }
    },
    props: {
        first_name: String,
        last_name: String,
        email: String,
        phone_number: String,
    },
    methods: {
        formatErrorMessage(messages: string | string[]) {
            if (Array.isArray(messages)) {
                return messages.join(' '); // Join array elements with a space
            }
            return messages; // Return empty string if no error message
        },
        getRoute() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'MyProfile') {
                this.settings = false
            } else if (routerChoice == 'Settings') {
                this.settings = true
            }
        },
        async getUserDetails() {
            const token = localStorage.getItem('AuthToken');

            try {
            const responseEmail = await axios.get('/auth/users/me', {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });     
            const userId = responseEmail.data.id;

                try {
                    const response = await axios.get(`/api/profiles`, {
                        headers: {
                            Authorization: `JWT ${token}`
                        },
                    });
                    this.country = response.data.country;
                    this.stateCity = response.data.stateCity;
                    this.postalCode = response.data.postalCode;
                    this.taxID = response.data.taxID;
                } catch (error) {
                    if (axios.isAxiosError(error)) {

                    if (error.response && error.response.status == 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 404) {
                        this.errors = error.response.data;
                    }
                    
                    } else {
                    this.errors.detail = 'An error occured. Please try again',
                    console.error('Non-Axios error:', error);
                    }
                }
            } catch (error) {
                           
                console.error('Error fetching user ID:', error);
                if (axios.isAxiosError(error)) {
                    //console.log('Axios error details:');
                    //console.log('Config:', error.config);
                    //console.log('Code:', error.code);
                    //console.log('Request:', error.request);
                    //console.log('Response:', error.response);
                    //alert(error.message);
                    if (error.response && error.response.status == 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 404) {
                        this.errors = error.response.data;
                    }                    
                    
                    } else {
                        
                    this.errors.detail = 'An error occured. Please try again',
                    console.error('Non-Axios error:', error);
                }
            }
            
        },
        checkRoute() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'Settings') {
                this.inputDisabled = false
            }
        },
        async saveUserDetails() {
            const token = localStorage.getItem('AuthToken');
            const uID = this.getUserDetails();
            const userData = {
                country: this.country,
                stateCity: this.stateCity,
                postalCode: this.postalCode,
                taxID: this.taxID,
            };

            try {
                const response = await axios.put(`/api/profiles`, userData, {
                    headers: {
                        Authorization: `JWT ${token}`,
                    }
                });
                this.getUserDetails();
                console.log('Profile updated successfully', response.data);
            } catch (error) {
                console.error('Error updating profile', error);
                if (axios.isAxiosError(error)) {

                if (error.response && error.response.status == 400) {
                    this.errors = error.response.data;
                } else if (error.response && error.response.status === 401) {
                    this.errors = error.response.data;
                } else if (error.response && error.response.status === 403) {
                    this.errors = error.response.data;
                } else if (error.response && error.response.status === 404) {
                    this.errors = error.response.data;
                }

                } else {
                this.errors.detail = 'An error occured. Please try again',
                console.error('Non-Axios error:', error);
                }
            }

        }

    },
    created() {
        this.getRoute();
        this.checkRoute();
    },
    mounted(){
        this.getUserDetails();
    },
    watch: {
        $route(to: { name: string; }, from: any) {
            this.getRoute();
            if (to.name === 'Settings') {
                this.inputDisabled = false
            }
        }
    },
})
