import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7760c916"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "general-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_User = _resolveComponent("User")!
  const _component_PersonalInfo = _resolveComponent("PersonalInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_User),
    _createVNode(_component_PersonalInfo, {
      first_name: "Sandra",
      last_name: "Osondu",
      email: "sandraosondu@'gmail.com'",
      phone_number: "08012345678"
    })
  ]))
}