
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import name from './ProfileName.vue'
import userphoto from './ProfilePhoto.vue'
import username from './ProfileUsername.vue'
import { toggleLoading } from '@/event-bus';

export default defineComponent({
    name: 'User',
    components: {
        name,
        userphoto,
        username
    },
    data() {
        return {
            settings: true,
            username: 'unknown',
            firstName: 'Not',
            lastName: 'logged in',
            errors: {
                username: this.username,
                firstName: this.firstName,
                lastName: this.lastName,
                detail: '',
            },
        }
    },
    methods: {
        formatErrorMessage(messages: string | string[]) {
            if (Array.isArray(messages)) {
                return messages.join(' '); // Join array elements with a space
            }
            return messages; // Return empty string if no error message
        },
        getRoute() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'MyProfile') {
                this.settings = false
            } else if (routerChoice == 'Settings') {
                this.settings = true
            }
        },
        async fetchUsername() {
            toggleLoading(true);
            const token = localStorage.getItem('AuthToken');
            try {
            const response = await axios.get('/auth/users/me/', {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });
            //alert('Success getting username!')
            this.username = response.data.username;
            this.firstName = 'Name';
            this.lastName = 'not set';
            const uID = response.data.id;
    //${uID}/
            try {
                    const response = await axios.get(`/api/profiles/`, { 
                        headers: {
                            Authorization: `JWT ${token}`
                        },
                    });
                    toggleLoading(false);
                    this.firstName = response.data[0].first_name;
//                    if (this.firstName.length === 0) {
//                        this.firstName = 'No first name';
//                    }
                    this.lastName = response.data[0].last_name;
//                    if (this.lastName.length === 0) {
//                        this.lastName = 'No last name';
//                    }
                } catch (error) {
                    toggleLoading(false);
                    if (axios.isAxiosError(error)) {

                    if (error.response && error.response.status == 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 404) {
                        this.errors = error.response.data;
                    }
                    
                    } else {
                    this.errors.detail = 'An error occured. Please try again',
                    console.error('Name Non-Axios error:', error);
                    }
                }
            } catch (error) {         
                toggleLoading(false);    
                console.error('Error fetching username:', error);
                if (axios.isAxiosError(error)) {
                    if (error.response && error.response.status == 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 404) {
                        this.errors = error.response.data;
                    }
                    
                    } else {
                    this.errors.detail = 'An error occured. Please try again',
                    console.error('Username error o! Non-Axios error:', error);
                }
            }
        },
//        async fetchName() {
//            const token = localStorage.getItem('AuthToken');
//            const uID = this.fetchUsername();
//
//            try {
//                    const response = await axios.get(`/api/profiles/${uID}`, {
//                        headers: {
//                            Authorization: `JWT ${token}`
//                        },
//                    });
//                    this.firstName = response.data.firstName;
//                    this.lastName = response.data.lastName;
//                } catch (error) {
//                    if (axios.isAxiosError(error)) {
//
//                    if (error.response && error.response.status == 400) {
//                        this.errors = error.response.data;
//                    } else if (error.response && error.response.status === 401) {
//                        this.errors = error.response.data;
//                    } else if (error.response && error.response.status === 403) {
//                        this.errors = error.response.data;
//                    } else if (error.response && error.response.status === 404) {
//                        this.errors = error.response.data;
//                    }
//                    
//                    } else {
//                    this.errors.detail = 'An error occured. Please try again',
//                    console.error('Non-Axios error:', error);
//                    }
//                }
//        }
    },
    created() {
        this.getRoute();
        this.fetchUsername();
    },
    mounted() {
        this.fetchUsername();
    },
    watch: {
        $route(to, from) {
            this.getRoute();
        }
    },
})
