
import Productdetails from "@/components/FavoriteCartComponent/Productdetails.vue";
import Shippingmode from "@/components/FavoriteCartComponent/Shippingmode.vue";
import { isLoading, toggleLoading } from "@/event-bus";
import { Product } from "@/router/types";
import axios from "axios";
import { defineComponent } from "vue";
import SecondHeaderVue from "../components/SecondHeader.vue";

export default defineComponent({
  name: "FavoritePage",
  components: {
    SecondHeaderVue,
    Productdetails,
    Shippingmode,
  },
  data() {
    return {
      faveItems: [] as Product[],
      favouritesExist: false,
      grandtotal: "",
      noShipping: false,
      isLoading,
    };
  },
  props: {
    product_id: String,
  },
  methods: {
    navigate() {
      this.$router.push("/section/male");
    },
    checkUserSignedIn() {
      const token = localStorage.getItem("AuthToken");

      if (token) {
      } else {
        this.$router.replace("/signin");
        alert("Sign in to view favourites");
      }
    },
    ifFavouriteExist() {
      toggleLoading(true);
      const token = localStorage.getItem("AuthToken");
      const product_id = this.product_id;

      axios
        .get("api/favourites/", {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((response) => {
          this.favouritesExist = true;
          this.faveItems = response.data;
          if (response.data.length === 0) {
            this.favouritesExist = false;
          }
          toggleLoading(false);
        })
        .catch((error) => {
          console.error("Error retrieving favourites:", error);
          this.favouritesExist = false;
          toggleLoading(false);
        });
    },
    removeItemFromFavoriteList(item_id: string) {
      this.faveItems = this.faveItems.filter((item) => item.id !== item_id);
    },
  },
  mounted() {
    this.ifFavouriteExist();
  },
  created() {
    this.checkUserSignedIn();
    document.title = "Favourites | Fondstore";
  },
});
