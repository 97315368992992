
import { computed, defineComponent, ref } from 'vue'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { toggleLoading } from '@/event-bus';
import { useUserStore } from "@/store/user"
import { useProductStore } from "@/store/product"
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default defineComponent({
    name: 'Productdetails',
    data() {
        return {
            menu_choice: '',
            inc_quantity: +1,
            dec_quantity: -1,
            l_order_number: this.order_number,
            is_favorite: false,
            just_favorited_id: '',
        }
    },
    props: {
        product_name: String,
        price: Number,
        size: Number,
        order_number: Number,
        liked: Boolean,
        product_id: String,
        favorite_id: String,
        totalprice: Number,
        photo: String,
        slug: String,
        favoriting_id: String,
    },
    emits: ['update_favorite', 'update_cart'],
    setup(props){
        const userStore = useUserStore();
        const productStore = useProductStore();
        const updated_quantity = ref(0)
        const unupdated_quantity = ref(0)
        const timeout = ref(0)
        const updatingCart = ref(false)
        const renderedTotalPrice = computed(() => props.price! * unupdated_quantity.value)

        return {
            updated_quantity,
            unupdated_quantity,
            userStore,
            productStore,
            timeout,
            updatingCart,
            renderedTotalPrice,
        }
    },
    mounted() {
        this.is_favorite = this.liked!;
        this.updated_quantity = this.unupdated_quantity = this.order_number!;
    },
    methods: {
        async handleFavourite() {
            const id = toast.loading(
                !!this.is_favorite ? 'Removing from favorites...' : 'Adding to favorites...',
                {
                    position: toast.POSITION.TOP_RIGHT,
                },
            );
            try {
                const res = await this.productStore.favoriteItem({
                    item_id: this.product_id!,
                    favorite_id: this.just_favorited_id || this.favorite_id!,
                    isFavorite: this.is_favorite
                })
                toast.update(id, {
                    render: "Done",
                    type: 'success',
                    isLoading: false,
                });
                this.is_favorite = !this.is_favorite;
                this.just_favorited_id = res?.favorite_id;
                this.$emit('update_favorite', this.product_id);
            } catch(error) {
                toast.update(id, {
                    render: "Error",
                    type: 'error',
                    isLoading: false,
                });
                console.log(error)
            } finally {
                setTimeout(() => toast.remove(id), 1000);
            }
        },
        async updateCart(amount: number){
            if ((this.unupdated_quantity! + amount) < 1) return
            // if the value is negative, it means the user has decreased the quantity
            // if the value is positive, it means the user has increased the quantity
            this.unupdated_quantity = this.unupdated_quantity! + amount;
            
            // using timeouts so that the request is not sent on every keypress, ie
            // immediately user increments/decrements the quantity
            if(!!this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                const id = toast.loading(
                    'Updating cart...',
                    {
                        position: toast.POSITION.TOP_RIGHT,
                    },
                );
                this.updatingCart = true;

                const newUpdatedValue = this.unupdated_quantity - this.updated_quantity
                try {
                    await this.productStore.updateItemInCart({
                        itemId: this.product_id!,
                        newQuantity: newUpdatedValue,
                    })
                    // once the request completes
                    this.$emit('update_cart', {
                        newCartTotal: (this.price! * this.unupdated_quantity) - (this.price! * this.updated_quantity!),
                    });
                    this.updated_quantity = this.unupdated_quantity;
                    toast.update(id, {
                        render: "Cart updated",
                        type: 'success',
                        isLoading: false,
                    });

                } catch(error) {
                    toast.update(id, {
                        render: "Error updating cart",
                        type: 'error',
                        isLoading: false,
                    });
                    console.log(error)
                } finally {
                    this.updatingCart = false;
                    setTimeout(() => toast.remove(id), 1000);
                }
            }, 1000);
        },
        async removeItemCart() {
            const id = toast.loading(
                'Removing from cart...',
                {
                    position: toast.POSITION.TOP_RIGHT,
                },
            );
            this.updatingCart = true;
            try {
                const {cartItemId} = await this.productStore.removeItemFromCart({itemId:this.product_id!})
                toast.update(id, {
                    render: "Item removed from cart",
                    type: 'success',
                    isLoading: false,
                });
                this.$emit('update_cart', {cartItemId, newCartTotal: -(this.price! * this.updated_quantity!)});
            } catch(error) {
                toast.update(id, {
                    render: "Error removing item from cart",
                    type: 'error',
                    isLoading: false,
                });
                console.log(error)
            } finally {
                this.updatingCart = false;
                setTimeout(() => toast.remove(id), 1000);
            }
        },
        getMenuChoice() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'FavoritePage') {
                this.menu_choice = 'Favorite'
            } else if (routerChoice == 'CartPage') {
                this.menu_choice = 'Cart'
            }
        },
    },
    watch: {
        $route(to: { name: string; }, from: any) {
            if (to.name === 'CartPage') {
                this.menu_choice = 'Cart'
            } else if (to.name === 'FavoritePage') {
                this.menu_choice = 'Favorite'
            }
        },

    },
    created() {
        this.getMenuChoice();
    },
})
