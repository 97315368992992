
import Carousel from "@/components/Carousel.vue";
import CategoriesSection from "@/components/CategoriesSection.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import BestOf from "@/components/HomeViewComponents/BestOfTheDay.vue";
import ProductGallery from "@/components/HomeViewComponents/ProductsGallery.vue";
import ScrollableSection from "@/components/ScrollableSection.vue";
import ScrollableSectionCard from "@/components/ScrollableSectionCard.vue";
import Spinner from "@/components/Spinner.vue";
import Tabs from "@/components/Tabs.vue";
import { toggleLoading } from "@/event-bus";
import { Category, Product, SectionTabs } from "@/router/types";
import { useHomeStore } from "@/store/home";
import { useProductStore } from "@/store/product";
import { useUserStore } from "@/store/user";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    Footer,
    BestOf,
    ProductGallery,
    Carousel,
    ScrollableSection,
    ScrollableSectionCard,
    Tabs,
    Spinner,
    CategoriesSection,
  },
  setup() {
    const homeStore = useHomeStore();
    const userStore = useUserStore();
    const productStore = useProductStore();

    return {
      homeStore,
      userStore,
      productStore,
    };
  },
  data() {
    return {
      products: [] as Product[],
      selectedCategoryProducts: [] as Product[],
      product: [] as Category[],
      photo: "@/assets/images/product-photos/Rectangle407item.png",
      selected: {},
      selectedSection: "",
      currentIndex: 0,
      translateValue: 0,
      autoScrollInterval: 0, //as NodeJS.Timeout | null,

      // mixed
      activeMixedTab: undefined,
      mixedTabList: [
        {
          name: "Popular",
          slug: "popular-items",
          requiresAuth: false,
        },
        {
          name: "Favourites",
          slug: "favourites",
          requiresAuth: true,
        },
        {
          name: "New Arrivals",
          slug: 'new_arrivals',
          requiresAuth: false,
        },
        {
          name: "Recently viewed",
          slug: "recently_viewed",
          requiresAuth: true,
        },
      ] as SectionTabs[],
    };
  },
  mounted() {
    document.title = "Fondstore";
    toggleLoading(true);

    Promise.all([
      this.productStore.categories.length === 0 &&
        this.productStore.fetchCategories(),
      this.homeStore.initTrending(),
      this.mixedTabList.map((tab) =>
        this.homeStore.fetchMixedItems({
          endpointName: tab.slug.replace("-", "_"),
          requiresAuth: Boolean(tab.requiresAuth),
        })
      ),
      this.homeStore.fetchBestDeals(),
      this.productStore.fetchAllItems(),
      this.productStore.fetchRandomItems(),
    ]);
  },
  watch: {
    requestingAllItems: {
      handler(val) {
        !val && toggleLoading(false);
      },
    },
  },
  methods: {
    // getRandomCategories(categories: [], count: number) {
    //   const shuffledCategories = [...categories];
    //   for (let i = shuffledCategories.length - 1; i > 0; i--) {
    //     const j = Math.floor(Math.random() * (i + 1));
    //     [shuffledCategories[i], shuffledCategories[j]] = [
    //       shuffledCategories[j],
    //       shuffledCategories[i],
    //     ];
    //   }
    //   return shuffledCategories.slice(0, count);
    // },
    // fetchProducts() {
    //   //for best of the day
    //   toggleLoading(false);
    //   axios
    //     .get("/api/items")
    //     .then((response: { data: any }) => {
    //       this.products = response.data;
    //       toggleLoading(false);
    //     })
    //     .catch((error: any) => {
    //       toggleLoading(false);
    //       console.log("Error fetching best of the day products:", error);
    //     });
    // },
    // toWomenPage() {
    //   this.$router.push("/section/female");
    // },
    // toMenPage() {
    //   this.$router.push("/section/male");
    // },
    // updateSelection(category: Category) {
    //   this.selected = category;
    //   const selectedSector = category.products[0];
    //   this.selectedSection = selectedSector.section.name;
    //   this.fetchProductsCategory(category.id);
    // },
    // fetchCategories() {
    //   axios
    //     .get("/api/categories")
    //     .then((response: { data: any }) => {
    //       const allCategories = response.data;
    //       this.product = this.getRandomCategories(allCategories, 4);
    //       this.selected = this.product[0];
    //       const categoryId = this.product[0].id;
    //       this.fetchProductsCategory(categoryId);
    //       const selectedSector = this.product[0].products[0];
    //       this.selectedSection = selectedSector.section.name;
    //     })
    //     .catch((error: any) => {
    //       console.log("Error fetching categories:", error);
    //     });
    // },
    // fetchProductsCategory(CategoryId: number) {
    //   toggleLoading(false);
    //   axios
    //     .get("/api/categories/")
    //     .then((response: { data: any }) => {
    //       toggleLoading(false);
    //       this.selectedCategoryProducts = response.data.find(
    //         (item: Category) => item.id === CategoryId
    //       ).products;
    //       console.log(this.selectedCategoryProducts);
    //     })
    //     .catch((error: any) => {
    //       toggleLoading(false);
    //       console.log("Error fetching selected category products:", error);
    //     });
    // },
    // seeMore() {
    //   this.$router.push({
    //     name: "Section",
    //     params: {
    //       section: this.selectedSection.toLowerCase(),
    //     },
    //   });
    // },
  },
  computed: {
    // filteredItems() {
    //   return this.products.slice(0, 2);
    // },
    // return the proper selected tab items for trending section
    renderedTrendingItems() {
      return (
        this.homeStore.getTrendingItems(
          String(this.homeStore.activeTrendingTab).toLowerCase() || "clothes"
        ) || []
      );
    },
    renderedMixedItems() {
      return (
        this.homeStore.getMixedItems(
          String(
            this.mixedTabList.find((tab) => tab.slug === this.activeMixedTab)
              ?.slug
          ).replace("-", "_") || "popular_items"
        ) || []
      );
    },
    bestDeals() {
      return this.homeStore.getMixedItems("best_deals") || [];
    },
    renderedRandomItems() {
      let prod;

        prod = this.productStore.getRandomItems();
      return prod;
    },
    requestingAllItems() {
      return this.productStore.requestingAllItems;
    },
  },
});
