
import AuthHeader from "@/components/AuthHeader.vue";
import { toggleLoading } from "@/event-bus";
import { useUserStore } from "@/store/user";
import axios from "axios";
import { defineComponent } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineComponent({
  name: "SignIn",
  components: {
    AuthHeader,
  },
  data() {
    return {
      buttonValue: "Sign In",
      buttonDisabled: false,
      username: "",
      email: "",
      password: "",
      errors: {
        username: this.username,
        email: this.email,
        password: this.password,
        detail: "",
      },
    };
  },
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  methods: {
    formatErrorMessage(messages: Function | string) {
      if (Array.isArray(messages)) {
        return messages.join(" ");
      }
      return messages;
    },
    async signIn() {
      toggleLoading(true);
      this.buttonValue = "Signing you in...";
      this.buttonDisabled = true;

      try {
        await this.userStore.signIn({
          username: this.username,
          email: this.email,
          password: this.password,
        });
        window.location.href = "/";
        toggleLoading(false);
      } catch (error) {
        toggleLoading(false);
        this.buttonValue = "Sign In";
        this.buttonDisabled = false;

        if (axios.isAxiosError(error)) {
          toast.error(error.message || "An error occured. Please try");
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 401) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 403) {
            this.errors = error.response.data;
          }
        } else {
          this.errors.detail = "An error occured. Please try again";
          console.error("Non-Axios error:", error);
        }
        console.error("Login failed:", error);
      }
    },
  },
});
