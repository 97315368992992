
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "Loading",
  methods: {
    verifyToken() {
      const token = localStorage.getItem("AuthToken");
      const refreshToken = localStorage.getItem("ReToken");

      if (token) {
        axios
          .post("/auth/jwt/verify", token)
          .then((response) => {
            const answer = response.data;
          })
          .catch((error) => {
            console.error("Error verifying token:", error);
          });
      }
    },
  },
  mounted() {
    //this.verifyToken();
    //document.body.classList.add('no-scroll');
  },
  created() {
    this.verifyToken();
  },
  //    beforeDestroy() {
  //        document.body.classList.remove('no-scroll');
  //        console.log('Removed!')
  //    },
});
