
import { defineComponent, ref, PropType } from "vue";
interface CarouselImage {
  src: string;
}

export default defineComponent({
  props: {
    images: {
      type: Array as PropType<CarouselImage[]>,
      required: true,
    },
  },
  data() {
    return {
      currentImageIndex: 0,
    };
  },
  computed: {
    currentImage(): CarouselImage {
      return this.images[this.currentImageIndex];
    },
  },
  methods: {
    goToImage(index: number): void {
      this.currentImageIndex = index;
    },
  },
});
