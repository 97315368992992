
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import FourthHeader from "@/components/FourthHeader.vue";
import ThirdHeader from "@/components/ThirdHeader.vue";
import ActualComponent from "@/components/MenuSidebar/ActualComponent.vue";
import { toggleLoading } from "@/event-bus";
import { useUserStore } from "@/store/user";

export default defineComponent({
  name: "DefaultLayout",
  components: {
    ThirdHeader,
    ActualComponent,
    FourthHeader,
  },
  data() {
    return {
      menu_choice: "Profile",
      Status: "Sign in",
      selectedOption: "my-profile",
    };
  },
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  methods: {
    getMenuChoice() {
      toggleLoading(true);
      const route = useRoute();
      const routerChoice = route.name;

      if (routerChoice == "MyProfile") {
        this.menu_choice = "Profile";
        this.selectedOption = "My profile";
        toggleLoading(false);
      } else if (routerChoice == "Orders") {
        this.menu_choice = "Orders";
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice == "HelpCenter") {
        this.menu_choice = "HelpCenter";
        this.selectedOption = "Help center";
        toggleLoading(false);
      } else if (routerChoice == "Settings") {
        this.menu_choice = "Settings";
        this.selectedOption = "Settings";
        toggleLoading(false);
      } else if (routerChoice === "OrdersOngoing") {
        this.menu_choice = "Ongoing";
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "OrderHistory") {
        this.menu_choice = "History";
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "OrderDrafts") {
        this.menu_choice = "Drafts";
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "Notifications") {
        this.menu_choice = "Notifications";
        this.selectedOption = "Notifications";
        toggleLoading(false);
      }
    },
    getSelectedOption() {
      toggleLoading(true);
      const route = useRoute();
      const routerChoice = route.name;

      if (routerChoice == "MyProfile") {
        this.selectedOption = "My profile";
        toggleLoading(false);
      } else if (routerChoice == "Orders") {
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice == "HelpCenter") {
        this.selectedOption = "Help center";
        toggleLoading(false);
      } else if (routerChoice == "Settings") {
        this.selectedOption = "Settings";
        toggleLoading(false);
      } else if (routerChoice === "OrdersOngoing") {
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "OrderHistory") {
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "OrderDrafts") {
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "Notifications") {
        this.selectedOption = "Notifications";
        toggleLoading(false);
      }
    },
    navigate(event: Event) {
      let selectedOption = (event.target as HTMLSelectElement).value;
      this.$router.push(`/${selectedOption}`);
    },
    checkUserSignedIn() {
      const token = localStorage.getItem("AuthToken");

      if (token) {
        this.Status = "Log out";
      } else {
        this.Status = "Sign in";
      }
    },
    async logUserOut() {
      await this.userStore.signOut();
      window.location.href = "/";
    },
  },
  watch: {
    $route(to: { name: string }, from: any) {
      if (to.name === "MyProfile") {
        this.menu_choice = "Profile";
        this.selectedOption = "My profile";
      } else if (to.name === "Orders") {
        this.menu_choice = "Orders";
        this.selectedOption = "Orders";
      } else if (to.name === "HelpCenter") {
        this.menu_choice = "Help Center";
        this.selectedOption = "Help center";
      } else if (to.name === "Settings") {
        this.menu_choice = "Settings";
        this.selectedOption = "Settings";
      } else if (to.name === "OrdersOngoing") {
        this.menu_choice = "Ongoing";
        this.selectedOption = "Orders";
      } else if (to.name === "OrderHistory") {
        this.menu_choice = "History";
        this.selectedOption = "Orders";
      } else if (to.name === "OrderDrafts") {
        this.menu_choice = "Drafts";
        this.selectedOption = "Orders";
      } else if (to.name === "Notifications") {
        this.menu_choice = "Notifications";
        this.selectedOption = "Notifications";
      }
    },
  },
  created() {
    this.getMenuChoice();
    document.title = "Account | FondStore";
    this.checkUserSignedIn();
  },
  mounted() {
    this.getSelectedOption();
  },
});
