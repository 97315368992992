
import HelpCentreFAQ from "@/components/MenuSidebar/HelpCentre.vue";
import { Product } from "@/router/types";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  // name: 'Notifications',
  components: {
    HelpCentreFAQ,
  },
  data() {
    return {
      Notify: [] as Product[],
    };
  },
  methods: {
    getNotification() {
      axios
        .get("https://fondstoremain-api.onrender.com/manager/faqs/")
        .then((response) => {
          this.Notify = response.data;
        })
        .catch((error) => {
          console.error("Error retrieving FAQs:", error);
        });
    },
  },
  mounted() {
    this.getNotification();
  },
});
