
import Spinner from "@/components/Spinner.vue";
import { isLoading, toggleLoading } from "@/event-bus";
import { useProductStore } from "@/store/product";
import { useUserStore } from "@/store/user";
import { defineComponent } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import SecondHeaderVue from "../components/SecondHeader.vue";
import StarRating from "../components/StarRating.vue"
import ReviewComponent from "../components/ReviewComponent.vue"

export default defineComponent({
  name: "Product Page",
  components: {
    SecondHeaderVue,
    Spinner,
    StarRating,
    ReviewComponent,
  },
  setup() {
    const productStore = useProductStore();
    const userStore = useUserStore();
    return {
      productStore,
      userStore,
    };
  },
  data() {
    return {
      product: {
        id: "",
        name: "",
        price: "",
        new_price: null,
        average_rating: 0,
        is_favourite: false,
        has_bought: false,
        order_number: null,
        size_number: null,
        description: "",
        image: "",
        additional_images: [""],
        favorite_id: null,
        colour: "",
        size: [{id: "", name: "", quantity: 0}],
        ratings: [{created_at: "", description: "", rating: 0, username: ""}]
      },
      sub_pictures_list: ["1", "2", "3", "4", "5"],
      //Note that the following should be in a dictionary when calling from the backend.
      product_name: "Bed",
      price: "30.00",
      is_favorite: false,
      rating_number: 4.5,
      order_number: 1,
      size_number: 35,
      buy_btn: "Buy Now",
      cart_btn: "Add to Cart",
      addingToCart: false,
      picture: "",
      logged_in: true,
      productIdFromRoute: this.$route.params?.id as string,
      favoriting: false,
      isLoading,
      activePictureIndex: 0,
      renderedBigImage: "",
      justFavouritedId: null as null | string,
      imagePreviewIsOpen: false,
      sizeSelected: undefined as number | undefined,
      ratings_value: 0,
      review_comments: [{created_at: "", description: "", rating: 0, username: ""}],
    };
  },
  methods: {
    share() {
      const urlToShare = window.location.href;

      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: urlToShare,
          })
          .then(() => console.log("Successfully shared"))
          .catch((error) => console.error("Error sharing:", error));
      } else {
        // Fallback for browsers that do not support the Web Share API
        alert(`Share this URL: ${urlToShare}`);
      }
    },
    async addToCart() {
      this.addingToCart = true;
      if (this.sizeSelected && this.product.size[this.sizeSelected-1]?.quantity <= 0) {
          toast.error("Unable to add to cart... This is because the item is out of stock.", {
          autoClose: 2000,
        });
        return;
      }
      try {
        await this.productStore.addItemToCart({
          item_id: this.product.id,
          quantity: this.order_number,
          size: this.sizeSelected && this.product.size[this.sizeSelected-1]?.quantity > 0 ? this.product.size[this.sizeSelected-1].id : "",
        });
        toast.success("Added to cart", {
          autoClose: 1000,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.addingToCart = false;
      }
    },
    async handleFavourite() {
      this.favoriting = true;
      try {
        const res = await this.productStore.favoriteItem({
          item_id: this.productIdFromRoute!,
          favorite_id: this.justFavouritedId || this.product.favorite_id!,
          isFavorite: this.is_favorite,
        });
        this.is_favorite = !this.is_favorite;
        this.justFavouritedId = res?.favorite_id;
      } catch (error) {
        console.log(error);
      } finally {
        this.favoriting = false;
      }
    },
    incrementNumber() {
      if (this.order_number < 1) return;
      this.order_number++;
    },
    decrementNumber() {
      if (this.order_number <= 1) return;
      this.order_number--;
    },
    changeSelectedSize(size: number) {
      this.sizeSelected = size
    },
    checkUserSignedIn() {
      const token = localStorage.getItem("AuthToken");

      if (token) {
      } else {
        this.logged_in = false;
      }
    },
    setActiveBigImage(index: number) {
      this.activePictureIndex = index;
      this.renderedBigImage = this.subPictures[index];
    },
    seek(direction: "prev" | "next") {
      const currentIndex = this.activePictureIndex;
      const lastIndex = this.subPictures.length - 1;
      if (direction === "prev") {
        if (currentIndex === 0) {
          this.setActiveBigImage(lastIndex);
        } else {
          this.setActiveBigImage(currentIndex - 1);
        }
      } else {
        if (currentIndex === lastIndex) {
          this.setActiveBigImage(0);
        } else {
          this.setActiveBigImage(currentIndex + 1);
        }
      }
    },
    autoPlay() {
      setInterval(() => {
        this.seek("next");
      }, 5000);
    },
    closePreview() {
      this.imagePreviewIsOpen = false;
    },
    openPreview() {
      this.imagePreviewIsOpen = true;
    },
  },
  async mounted() {
    document.title = "Product | Fondstore";
    toggleLoading(true);
    this.product = await this.productStore.fetchItemDetails({
      id: this.productIdFromRoute,
    });
    toggleLoading(false);
    this.renderedBigImage = this.subPictures[0] || this.product?.image;
    this.is_favorite = this.product?.is_favourite;
    this.autoPlay();
    this.productStore.fetchCartItems();
    this.ratings_value = this.product?.average_rating;
    this.review_comments = this.product?.ratings;
    this.sizeSelected = this.product.size[0].name.split('-')[0] === "No Size" ? 1 : undefined;
  },
  created() {
    this.checkUserSignedIn();
    // if product id is not available, redirect to home page
    if (!this.productIdFromRoute) this.$router.push("/");
  },
  computed: {
    subPictures() {
      return (
        this.product?.additional_images ||
        (!!this.product?.image && [this.product.image]) ||
        []
      );
    },
    nl2Br() {
      return (text: string) => text?.replace(/\n/g, "<br />");
    },
  },
});
