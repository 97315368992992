
import axios from 'axios'
import AuthHeader from '@/components/AuthHeader.vue';
import { Product } from '@/router/types'
import { defineComponent } from 'vue'
import { toggleLoading } from '@/event-bus';
import { useUserStore } from '@/store/user';

export default defineComponent({
    name: 'SignUpVerified',
    components: {
        AuthHeader,
    },
    data() {
        return {
            status: 'linksent',
            buttonValue: 'Sign In',
        }
    },
    methods: {
        signIn() {
            this.$router.push('/signin')
        },
    },
    setup() {
        const userStore = useUserStore()
        return {
            userStore,
        }
    },
    mounted() {
        // hit the verification endpoint
        this.userStore.verifyUser({
            token: this.$route.params.token as string,
            uId: this.$route.params.uid as string,
        })
    }
})
