
import { defineComponent } from 'vue';
import axios from 'axios';
import { toggleLoading } from '@/event-bus';

export default defineComponent({
    name: 'OrderSummaryProducts',
    data() {
        return {
            inc_quantity: +1,
            dec_quantity: -1,
        }
    },
    props: {
        photo: String,
        product_name: String,
        price: Number,
        order_number: Number,
        product_id: String,
    },
    methods: {
        increaseQuantity() {
            toggleLoading(true);
//            if (this.inc_quantity < 1) {
//
//            } else {
//                this.inc_quantity++;
//            }
            const token = localStorage.getItem('AuthToken');

            axios.post('/api/carts/add_to_cart/', {product_id: this.product_id, quantity: this.inc_quantity}
            )
            .then(response => {
                toggleLoading(false);
                console.log('Product quantity increased:', response.data);
            //this.$emit('reloadCart');
                window.location.reload();
            })
            .catch(error => {
                toggleLoading(false);
                console.error('Error increasing product:', error);
            });
        },
        decreaseQuantity() {
            toggleLoading(true);
//            if (this.dec_quantity <= 1) {
//
//            } else {
//                this.dec_quantity--;
//            }
            const token = localStorage.getItem('AuthToken');

            axios.post('/api/carts/add_to_cart/', {product_id: this.product_id, quantity: this.dec_quantity}
            )
            .then(response => {
                toggleLoading(false);
                console.log('Product quantity decreased:', response.data);
                this.$emit('reloadCart');
                window.location.reload();
            })
            .catch(error => {
                toggleLoading(false);
                console.error('Error decreasing product:', error);
            });
        },
    },
})
