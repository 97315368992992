
import AuthHeader from "@/components/AuthHeader.vue";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SignUp",
  components: {
    AuthHeader,
  },
  data() {
    return {
      status: "nolink",
      buttonValue: "Sign Up",
      buttonDisabled: false,
      username: "",
      email: "",
      password: "",
      re_password: "",
      errors: {
        username: this.username,
        email: this.email,
        password: this.password,
        re_password: this.re_password,
        non_field_errors: "",
        detail: "",
      },
    };
  },
  methods: {
    formatErrorMessage(messages: Function | string) {
      if (Array.isArray(messages)) {
        return messages.join(" ");
      }
      return messages;
    },
    async registerUser() {
      this.buttonValue = "Signing you up...";
      this.buttonDisabled = true;

      const userData = {
        username: this.username,
        email: this.email,
        password: this.password,
        re_password: this.re_password,
      };
      const userDetails = {
        username: this.username,
        email: this.email,
        password: this.password,
      };

      try {
        const response = await axios.post("/auth/users/", userData);
        console.log("Signup successful:", response.data);
        this.status = "linksent";

        setTimeout(() => {
          this.$router.push({ name: "signin" });
        }, 5000);

        //                try {
        //                    const responseToken = await axios.post('/auth/jwt/create', userDetails);
        //                    this.$router.push({name: 'home'})
        //                    const token = responseToken.data.access;
        //                    localStorage.setItem('AuthToken', token)
        //                } catch (error) {
        //                    this.buttonValue = 'Sign Up'
        //                this.buttonDisabled = false;
        //
        //                if (axios.isAxiosError(error)) {
        //                    //console.log('Axios error details:');
        //                    //console.log('Config:', error.config);
        //                    //console.log('Code:', error.code);
        //                    //console.log('Request:', error.request);
        //                    //console.log('Response:', error.response);
        //                    alert(error.message);
        //                    if (error.response && error.response.status === 400) {
        //                        this.errors = error.response.data;
        //                    } else if (error.response && error.response.status === 401) {
        //                        this.errors = error.response.data;
        //                    } else if (error.response && error.response.status === 403) {
        //                        this.errors = error.response.data;
        //                    }
        //                } else {
        //                    console.error('Non-Axios error:', error);
        //                    this.errors.detail = 'An error occured. Please try again'
        //                }
        //                console.error('Getting token failed:', error);
        //                }
      } catch (error) {
        this.buttonValue = "Sign Up";
        this.buttonDisabled = false;

        if (axios.isAxiosError(error)) {
          // alert(error.message);
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 401) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 403) {
            this.errors = error.response.data;
          }
        } else {
          console.error("Non-Axios error:", error);
          this.errors.detail = "An error occured. Please try again";
        }
        console.error("Registration failed:", error);
      }
    },
  },
});
