
import ProductdetailsVue from "@/components/FavoriteCartComponent/Productdetails.vue";
import ShippingmodeVue from "@/components/FavoriteCartComponent/Shippingmode.vue";
import { isLoading, toggleLoading } from "@/event-bus";
import { useProductStore } from "@/store/product";
import { defineComponent } from "vue";
import SecondHeaderVue from "../components/SecondHeader.vue";

export default defineComponent({
  name: "CartPage",
  components: {
    SecondHeaderVue,
    ProductdetailsVue,
    ShippingmodeVue,
  },
  setup() {
    const productStore = useProductStore();
    return {
      productStore,
    };
  },
  data() {
    return {
      isLoading,
      grandtotal: 0,
      // cartItems: [] as Product[],
      // cartExist: false,
      // shouldGetCart: true,
    };
  },
  computed: {
    cartExist() {
      return this.productStore.cartDetails?.cart_items?.length > 0;
    },
    cartItems() {
      return this.productStore.cartDetails?.cart_items;
    },
  },
  methods: {
    handleCartUpdate({
      cartItemId,
      newCartTotal,
    }: {
      cartItemId: undefined | number;
      newCartTotal: undefined | number;
    }) {
      if (newCartTotal) {
        this.grandtotal += newCartTotal;
      }

      if (cartItemId) {
        this.productStore.cartDetails.cart_items =
          this.productStore.cartDetails.cart_items.filter(
            (item) => item.id !== cartItemId
          );
      }
    },
  },
  async mounted() {
    document.title = "Cart | Fondstore";

    toggleLoading(true);
    await this.productStore.fetchCartItems();
    toggleLoading(false);

    this.grandtotal = this.productStore.cartDetails?.price_grand_total;
  },
});
