//import './global-config';
import * as mdijs from "@mdi/js";
import axios from "axios";
import mdiVue from "mdi-vue/v3";
import { createPinia } from "pinia";
import { createApp } from "vue";
import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import App from "./App.vue";
import router from "./router";

const pinia = createPinia();
const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(Vue3Toasity, {
  autoClose: 3000,
} as ToastContainerOptions);
app.use(mdiVue, {
  icons: mdijs,
});
app.mount("#app");

// global axios defaults
axios.defaults.baseURL =
  process.env.VUE_APP_API_URL || "https://fondstoremain-api.onrender.com";
// this is used to watch for when users token expires
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.data?.code === "token_not_valid" ||
      error.response?.data?.code === "user_not_found"
    ) {
      // delete user from local storage
      localStorage.removeItem("user");
      localStorage.removeItem('AuthToken')
      localStorage.removeItem('ReToken')
    }
    return Promise.reject(error);
  }
);
