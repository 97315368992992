
import { defineComponent } from "vue";

export default defineComponent ({
  name: 'name',
  props: {
    first_name: String,
    last_name: String,
  }
})
