/* stores/product.js */
import { defineStore } from 'pinia';
import axios from 'axios';
import { Category, Product, PaginatedResponse } from '@/router/types';
import { useUserStore } from './user';

export const useProductStore = defineStore("product", {
  state: () => ({
    categories: [] as Category[],
    subcategories: [] as any[],
    sections: [] as any[],
    items: {} as PaginatedResponse<any[]> | null,
    allItems: {} as PaginatedResponse<any[]>,
    requestingAllItems: false,
    cartDetails: {} as {
      id: string;
      cart_items: {
        id: number;
        cart: string;
        item: Product[];
        quantity: number;
        price_sub_total: number;
      }[];
      total_quantity: number;
      price_grand_total: number;
    },
    cachedCartId: localStorage.getItem("cartID"),
    orders: [] as any[],
    popularProducts: [] as any[],
  }),
  getters: {
    getSubcategoryById: (state) => (id: number) => {
      return state.subcategories.filter(
        (subcategory) => subcategory.category === id
      );
    },
    getSection:
      (state) =>
      ({
        categoryId,
        subCategoryId,
      }: {
        categoryId: number;
        subCategoryId: number;
      }) => {
        return state.sections.filter((section) => {
          return (
            section.category === categoryId &&
            section.sub_category === subCategoryId
          );
        });
      },
    getRandomItems: (state) => () => {
      return state.popularProducts;
    },
  },
  actions: {
    async fetchCategories() {
      try {
        const { data } = await axios.get("/api/categories/");
        this.categories = data;
      } catch (error) {
        console.log({ error });
      }
    },
    async fetchRandomItems() {
      try {
        const res = await axios.get("api/items/random_products");
        if (res.status === 200) {
          this.popularProducts = res.data;
        }
        throw new Error("Something went wrong while fetching random products");
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSubCategories() {
      try {
        const { data } = await axios.get(`/api/subcategories/`);
        this.subcategories = data;
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    async fetchSections() {
      try {
        const { data } = await axios.get(`/api/sections/`);
        this.sections = data;
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    async fetchItemsInCategorySubcategoryAndSection({
      categoryId,
      subCategoryId,
      sectionId,
    }: {
      categoryId: number;
      subCategoryId: number;
      sectionId: number;
    }) {
      const user = useUserStore();
      this.items = null;
      try {
        const { data } = await axios.get(
          `/api/categories/${categoryId}/subcategories/${subCategoryId}/sections/${sectionId}/items/`,
          user.getAuthHeader
        );
        this.items = data;
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    async fetchAllItems() {
      this.requestingAllItems = true;
      const user = useUserStore();
      try {
        const { data } = await axios.get(`/api/items/`, user.getAuthHeader);
        this.allItems = data;
        return data;
      } catch (error) {
        console.log({ error });
      } finally {
        this.requestingAllItems = false;
      }
    },
    async favoriteItem({
      item_id,
      favorite_id,
      isFavorite,
    }: {
      item_id?: string;
      favorite_id: string;
      isFavorite: boolean;
    }) {
      const url = isFavorite
        ? `/api/favourites/${favorite_id}/`
        : "/api/favourites/";
      const user = useUserStore();
      try {
        const requestData = isFavorite ? undefined : { item_id }; // Include item_id when favoriting
        const method = isFavorite ? "delete" : "post";
        // doing this like this because when I pass in {} or undefined
        // for the delete request as request data, the request fails
        // unfavorite
        let res: any = null;
        if (isFavorite) {
          await axios.delete("" + url, user.getAuthHeader);
        } else {
          // favorite
          res = (await axios.post("" + url, requestData, user.getAuthHeader))
            .data;
        }
        return res;
      } catch (error) {
        throw error;
      }
    },
    async fetchItemDetails({ id }: { id: string }) {
      const user = useUserStore();

      try {
        const { data } = await axios.get(
          `/api/items/${id}/`,
          user.getAuthHeader
        );
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    async mergeCart() {
      const user = useUserStore();
      try {
        const { data } = await axios.post(`/api/merge_carts/`, undefined, {
          ...user.getAuthHeader,
          withCredentials: true,
        });
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    async fetchCartItems() {
      const user = useUserStore();

      try {
        const { data } = await axios.get(`/api/cart/`, {
          ...user.getAuthHeader,
          withCredentials: true,
        });
        this.cartDetails = data?.[0] || {};
        localStorage.setItem("cartID", this.cartDetails.id); // from legacy code
        this.cachedCartId = this.cartDetails.id;
        return data?.[0];
      } catch (error) {
        console.log({ error });
      }
    },
    async addItemToCart({
      item_id,
      quantity,
      size,
    }: {
      item_id: string;
      quantity: number;
      size: string;
    }) {
      const user = useUserStore();
      // check if cart id already exists, if not, fetch cart items
      // fron design; cartId is required to add item to cart and can be
      // gotten from the cart details
      const cartId = this.cachedCartId || (await this.fetchCartItems())?.id;

      try {
        const { data } = await axios.post(
          `/api/cart/${cartId}/cart_items/`,
          {
            item_id,
            quantity,
            size,
          },
          user.getAuthHeader
        );
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    async getCartItem({ itemId }: { itemId: string }) {
      const user = useUserStore();
      const cartId = this.cachedCartId;

      try {
        const { data } = await axios.get(
          `/api/cart/${cartId}/cart_items/`,
          user.getAuthHeader
        );
        return data?.find((cartItem: any) => cartItem.item.id === itemId);
      } catch (error) {
        console.log({ error });
      }
    },
    async updateItemInCart({
      itemId,
      newQuantity,
    }: {
      itemId: string;
      newQuantity: number;
    }) {
      const user = useUserStore();
      const cartId = this.cachedCartId;

      try {
        const { data } = await axios.post(
          `/api/cart/${cartId}/cart_items/`,
          {
            item_id: itemId,
            quantity: newQuantity,
          },
          user.getAuthHeader
        );
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    async removeItemFromCart({ itemId }: { itemId: string }) {
      const user = useUserStore();
      const cartId = this.cachedCartId;

      // this is necessary to obtain the cart item id which is a number
      const cartItemId = (await this.getCartItem({ itemId }))?.id;
      try {
        const { data } = await axios.delete(
          `/api/cart/${cartId}/cart_items/${cartItemId}/`,
          user.getAuthHeader
        );
        return { data, cartItemId };
      } catch (error) {
        throw error;
      }
    },
    async fetchOrders() {
      const user = useUserStore();

      try {
        const { data } = await axios.get(
          `/api/orders/?cart_id=${this.cachedCartId}`,
          user.getAuthHeader
        );
        this.orders = data;
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    async fetchAllOrders() {
      const user = useUserStore();

      try {
        const { data } = await axios.get(`/api/orders/`, user.getAuthHeader);
        this.orders = data;
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    async fetchOrderDetails({ orderId }: { orderId: string }): Promise<any> {
      const user = useUserStore();

      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await axios.get(
            `/api/orders/${orderId}/order_items/`,
            user.getAuthHeader
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    async createOrder() {
      const user = useUserStore();

      try {
        const { data } = await axios.post(
          `/api/orders/`,
          {
            cart_id: this.cachedCartId,
          },
          user.getAuthHeader
        );
        return data;
      } catch (error) {
        console.log({ error });
      }
    },
    setItems(items: PaginatedResponse<any[]>) {
      this.items = items;
    },
  },
});