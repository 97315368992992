
import { defineComponent } from 'vue'
import axios from 'axios';

export default defineComponent({
    name: 'ItemComponent',
    data() {
      return {
        quantity: 1,
        cartbtn: 'Buy now',
        buttonDisabled: false,
        liked_to_favorite: false,
      }
    },
    props: {
        picture_url: String,
        item_name: String,
        price: Number,
        unique_tag: String,
        liked_to_favorite: Boolean,
        id: String,
        slug: String,
        //The unique tag is to tally with the options underneath the mens tab [shirts, pants, accessories, and all of that] they are the unique tag.
        //liked_to_favorite is to ensure if the user has clicked on the liked button therby making it a favorite item
    },
    methods: {
    addToCart() {
        const token = localStorage.getItem('AuthToken');
        this.cartbtn = 'Adding to cart...'
        this.buttonDisabled = true;

        axios.post('/api/carts/add_to_cart/', {product_id: this.id, quantity: this.quantity}
        )
        .then(response => {
          console.log('Product added to cart:', response.data);
          this.cartbtn = 'Added'
          this.buttonDisabled = false;
        })
        .catch(error => {
          console.error('Error adding product to cart:', error);
          this.cartbtn = 'Buy now'
          this.buttonDisabled = false;
        });
    },
    addToFavorites() {
        if (this.liked_to_favorite == false) {
            const token = localStorage.getItem('AuthToken');
            axios.post('/api/profiles/add_to_favourites/', {product_id: this.id},
            {
            headers: {
                        Authorization: `JWT ${token}`,
                    },
            }
            )
            .then(response => {
            alert('Item successfully added to cart!')
            console.log('Item successfully added to favourites:', response.data);
            this.liked_to_favorite = true
            })
            .catch(error => {
            alert('Error adding item to favorites')
            console.error('Error adding item to favourites:', error);
            });
        } else {
            const token = localStorage.getItem('AuthToken')

            axios.post('/api/profiles/remove_from_favourites/', {product_id: this.id},
            {
            headers: {
                        Authorization: `JWT ${token}`,
                    },
            })
            .then(response => {
                this.liked_to_favorite = false
                console.log('Item successfully removed from favourites:', response.data)
            })
            .catch(error => {
                alert('Error removing item from favorites')
                console.error('Error removing item from favourites:', error)
            })
        }
      },
    }
})
