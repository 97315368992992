
import { defineComponent } from 'vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { toggleLoading } from '@/event-bus';

const data = ref({
  selectedImage: null,
});

export default defineComponent({
    name: 'PersonalInfo',
    data () {      
        return {
            Save: 'Save',
            file: '',
            settings: true,
            firstName: '-',
            lastName: '-',
            emailAddress: '-',
            mobileNumber: '-',
            errors: {
                detail: "",
            },
            inputDisabled: true,
            fileContent: null,
            
        }
    },
    props: {
        first_name: String,
        last_name: String,
        email: String,
        phone_number: String,
    },
    methods: {
//        handleFileChange(event: Event) {
//            this.selectedFile = event?.target?.files?.[0] || null;
//        },
        formatErrorMessage(messages: string | string[]) {
            if (Array.isArray(messages)) {
                return messages.join(' '); // Join array elements with a space
            }
            return messages; // Return empty string if no error message
        },
        getRoute() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'MyProfile') {
                this.settings = false
            } else if (routerChoice == 'Settings') {
                this.settings = true
            }
        },
        async getUserDetails() {
            toggleLoading(true);
            const token = localStorage.getItem('AuthToken');

            try {
            const responseEmail = await axios.get('/auth/users/me', {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });     
            this.emailAddress = responseEmail.data.email;
            const userId = responseEmail.data.id;
            //console.log(`${userId}`)

                try {
                    // What shouldbe gotten from this API is mobile number and profile photo
                    const response = await axios.get(`/api/profile/`, { //${userId}/
                        headers: {
                            Authorization: `JWT ${token}`
                        },
                    });
                    this.mobileNumber = response.data[0].phone_number;
                    this.firstName = response.data[0].first_name;
                    this.lastName = response.data[0].last_name;
                    toggleLoading(false);
                } catch (error) {
                    toggleLoading(false);
                    if (axios.isAxiosError(error)) {

                    if (error.response && error.response.status == 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 404) {
                        this.errors = error.response.data;
                    }
                    
                    } else {
                    this.errors.detail = 'An error occured. Please try again',
                    console.error('Name error o! Non-Axios error:', error);
                    }
                }
            } catch (error) {
                toggleLoading(false);          
                console.error('Error fetching email address:', error);
                if (axios.isAxiosError(error)) {
                    if (error.response && error.response.status == 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 404) {
                        this.errors = error.response.data;
                    }                    
                    
                    } else {
                        
                    this.errors.detail = 'An error occured. Please try again',
                    console.error('Email error o! Non-Axios error:', error);
                }
            }
            
        },
        checkRoute() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'Settings') {
                this.inputDisabled = false
            }
        },
//        imageUpload() {
//            const inputElement: HTMLInputElement = document.getElementById('fileInput') as HTMLInputElement;
//
//            inputElement.addEventListener('change', handleFileSelect);
//
//            function handleFileSelect(event: Event) {
//            const target = event.target as HTMLInputElement;
//                if (target.files && target.files.length > 0) {
//                    const selectedFile: File = target.files[0];
//                    sendImage(selectedFile);
//                }
//            }
//        },
        //imageUpload(event: Event) {
        //        const input = event.target as HTMLInputElement;
        //        if (input.files && input.files[0]) {
        //            data.selectedImage = input.files[0]
        //        }
        //},
        async saveUserDetails() {
            this.Save = 'Saving'

            const token = localStorage.getItem('AuthToken');
            const userData = {
                first_name: this.firstName,
                last_name: this.lastName,
                phone_number: this.mobileNumber,            
            };
//            let fileInput = this.$refs.file as HTMLInputElement;
//            let image = fileInput.files;
//            if (image && image[0]) {
//                let reader = new FileReader
//                reader.onload = (e) => {
//                    this.fileContent = e.target.result
//                }
//                reader.readAsDataURL(image[0])
//                this.$emit('fileInput', image[0])
//            }

            const userForm = new FormData();
            userForm.append('first_name', String(this.firstName.trim()));
            userForm.append('last_name', String(this.lastName.trim()));
            userForm.append('phone_number', this.mobileNumber.trim() as string);
            userForm.append('image', this.file);
            //userForm.append('image', data.selectedImage);

            if (this.firstName === 'Name' || this.lastName === 'not set') {
                if (this.file === '') {
                    try {
                    const response = await axios.post(`/api/profile/`, userForm, {
                                    headers: {
                                        Authorization: `JWT ${token}`,
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                    }
                                });
                                this.Save = 'Save'
                                console.log('Profile update successful!:', response.data)
                                this.getUserDetails
                    } catch (error) {
                        this.Save = 'Save'
                        console.error('A Form failed:', error)
                    }
                } else {
                    try {
                    const response = await axios.post(`/api/profile/`, userForm, {
                                    headers: {
                                        Authorization: `JWT ${token}`,
                                        'Content-Type': 'multipart/form-data',
                                    }
                                });
                                console.log('Profile update successful!:', response.data)
                                this.getUserDetails
                                this.Save = 'Save'
                    } catch (error) {
                        this.Save = 'Save'
                        console.error('B Form failed:', error)
                    }
                }
                
            } else {
                if (this.file === '') {
                    try { //Just wanna get userID
                    const responseEmail = await axios.get('/auth/users/me', {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                    });     
                    const userId = responseEmail.data.id;
                    //console.log(`${userId}`)

                    try {
                        const response = await axios.post(`/api/profile/`, userForm, { //${userId}/
                                        headers: {
                                            Authorization: `JWT ${token}`,
                                            'Content-Type': 'application/x-www-form-urlencoded',
                                        }
                                    });
                                    this.Save = 'Saved'
                                    console.log('Profile update successful!:', response.data)
                                    this.getUserDetails
                    } catch (error) {
                        this.Save = 'Error. Re-save'
                        console.error('C Form failed:', error)
                    }
                    } catch (error) {
                        this.Save = 'Save'
                        console.error('Failed:', error)
                    }
                } else {
                    try { //Just wanna get userID
                    const responseEmail = await axios.get('/auth/users/me', {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                    });     
                    const userId = responseEmail.data.id;
                    //console.log(`${userId}`)

                    try {
                        const response = await axios.put(`/api/profile/${userId}/`, userForm, {
                                        headers: {
                                            Authorization: `JWT ${token}`,
                                            'Content-Type': 'multipart/form-data',
                                        }
                                    });
                                    this.Save = 'Save'
                                    console.log('Profile update successful!:', response.data)
                                    this.getUserDetails
                    } catch (error) {
                        this.Save = 'Save'
                        console.error('D Form failed:', error)
                    }
                    } catch (error) {
                        this.Save = 'Save'
                        console.error('Failed:', error)
                    }
                }
                
            }
        },
//        imageUpload() {
//        const fileInput = this.$refs.file as HTMLInputElement;
//
//        if (fileInput && fileInput.files && fileInput.files.length > 0) {
//            this.file = fileInput.files[0];
//        }
//        },
//        async profilePhoto() {
//            const token = localStorage.getItem('AuthToken');
//            const fileInput = this.$refs.file as HTMLInputElement;
//
//            if (fileInput && fileInput.files && fileInput.files.length > 0) {
//                this.file = fileInput.files[0];
//            };
//
//            let formData = new FormData();
//            formData.append('image', this.file);
//
//            if (this.firstName === 'Name' || this.lastName === 'not set') {
//                try {
//                    const response = await axios.post('`/api/profile/`', formData, {
//                        headers: {
//                            Authorization: `JWT ${token}`,
//                            'Content-Type': 'multipart/form-data'
//                        }
//                    });
//                    console.log('Profile photo update successful!:', response.data)
//                    this.getUserDetails
//                } catch (error) {
//                    console.error('Upload failed:', error)
//                }
//            } else {
//                try { //Just wanna get userID
//                const responseEmail = await axios.get('/auth/users/me', {
//                headers: {
//                    Authorization: `JWT ${token}`,
//                },
//                });     
//                const userId = responseEmail.data.id;
//                //console.log(`${userId}`)
//
//                try {
//                    const response = await axios.put(`/api/profile/${userId}/`, formData, {
//                                    headers: {
//                                        Authorization: `JWT ${token}`,
//                                        'Content-Type': 'multipart/form-data',
//                                    }
//                                });
//                                console.log('Profile update successful!:', response.data)
//                                this.getUserDetails
//                } catch (error) {
//                    console.error('Form failed:', error)
//                }
//                } catch (error) {
//                    console.error('Failed:', error)
//                }
//            }
//        },
    },
    created() {
        this.getRoute();
        this.checkRoute();
        this.getUserDetails(); 
    },
    mounted(){
        
    },
    watch: {
        $route(to: { name: string; }, from: any) {
            this.getRoute();
            if (to.name === 'Settings') {
                this.inputDisabled = false
            }
        }
    },
})
