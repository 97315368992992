
import { defineComponent } from 'vue';
import User from '@/components/UserComponents/UserDetails.vue'
import PersonalInfo from '@/components/UserComponents/PersonalInformation.vue'
import Address from '@/components/UserComponents/Address.vue'

export default defineComponent ({
    name: 'MyProfile',
    components: {
        User,
        PersonalInfo,
        Address,
    },
})
