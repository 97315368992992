import { openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1728bd16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-carousel" }
const _hoisted_2 = { class: "carousel-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "pagination" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("img", {
            key: _ctx.currentImageIndex,
            src: _ctx.currentImage.src,
            class: "carousel-image"
          }, null, 8, _hoisted_3))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (_, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          onClick: ($event: any) => (_ctx.goToImage(index)),
          class: _normalizeClass([{ active: index === _ctx.currentImageIndex }, "pagination-dot"])
        }, null, 10, _hoisted_5))
      }), 128))
    ])
  ]))
}