
import { defineComponent } from "vue";

export default defineComponent ({
  name: 'userphoto',
  data() {
    return {
        user_logged_in: false,
    }
  }
})
