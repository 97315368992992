import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15b68fd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "catalogue-gallery-cards" }
const _hoisted_2 = { class: "catalogue-card" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "catalogue-card-details" }
const _hoisted_5 = { class: "item-name" }
const _hoisted_6 = { class: "item-price" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: '/products/' + _ctx.slug + '/' + _ctx.id + '/'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.photo,
            alt: ""
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.name), 1),
              _createElementVNode("p", _hoisted_6, "₦" + _toDisplayString(_ctx.price), 1)
            ]),
            _createElementVNode("button", {
              disabled: _ctx.buttonDisabled,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addToCart && _ctx.addToCart(...args)))
            }, _toDisplayString(_ctx.cartbtn), 9, _hoisted_7)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}