
import { defineComponent } from 'vue';
import User from '@/components/UserComponents/UserDetails.vue'
import PersonalInfo from '@/components/UserComponents/PersonalInformation.vue'
import Address from '@/components/UserComponents/Address.vue'

export default defineComponent ({
    name: 'Settings',
    components: {
        User,
        PersonalInfo,
        Address,
    },
    created() {
        document.title = 'Settings | Fondstore';
    }
})
