
import {defineComponent} from 'vue'
import axios from 'axios';

export default defineComponent({
    name: 'ProductGallery',
    data() {
      return {
        quantity: 1,
        cartbtn: 'Shop now',
        buttonDisabled: false,
      }
    },
    props: {
        name: String,
        price: Number,
        photo: String,
        id: String,
        slug: String,
        is_favorite: Boolean,
    },
    methods: {
      addToCart() {
        const token = localStorage.getItem('AuthToken');
        this.cartbtn = 'Adding to cart...'
        this.buttonDisabled = true;

        axios.post('/api/carts/add_to_cart/', {product_id: this.id, quantity: this.quantity})
        .then(response => {
          console.log('Product added to cart:', response.data);
          this.cartbtn = 'Added'
          this.buttonDisabled = false;
        })
        .catch(error => {
          console.error('Error adding product to cart:', error);
          this.cartbtn = 'Shop now'
          this.buttonDisabled = false;
        });
      }
    }
})
