
import { toggleLoading } from "@/event-bus";
import { Bank, Product } from "@/router/types";
import { usePaymentStore } from "@/store/payment";
import { useProductStore } from "@/store/product";
import axios from "axios";
import { defineComponent } from "vue";
import paystack from "vue-paystack";
import OrderSummaryProducts from "../components/CheckOutComponent/OrderSummaryComponent.vue";
import ThirdHeaderVue from "../components/ThirdHeader.vue";
import { useUserStore } from "@/store/user";

export default defineComponent({
  name: "CheckOut",
  components: {
    ThirdHeaderVue,
    OrderSummaryProducts,
    paystack,
  },
  data() {
    return {
      cartItems: [] as any[],
      grandtotal: 0,
      respond: "",
      sub_total: 0,
      metadata: {
        custom_fields: [
          {
            display_name: "Product",
            variable_name: "product",
            value: "Sample Product",
          },
        ],
      },
      reference: "",
      shipping_type: "-",
      phone_code: 234,
      phone_menu: {
        //    'US': '323',
        NGN: "234",
        //   'UK': '111',
      },
      states_menu: [] as Product[],
      banks_menu: [] as Bank[],
      lga_menu: [] as Product[],
      country_selector_code: 234,
      country_selector: String,
      delivery_name: String,
      delivery_mobile_number: "",
      delivery_email_address: "",
      delivery_lga: "",
      delivery_lga_id: "",
      delivery_state: "",
      delivery_state_id: "",
      selectedBank: "",
      delivery_address: String,
      //schedule_delivery_date: Date,
      //schedule_delivery_note: String,
      payment_email_address: String,
      payment_card_number: Number,
      payment_cvv: Number,
      payment_expiry_date: String,
      payment_card_name: String,
      save_card_details: false,
      billing_address: String,
      billing_address_confirm: true,
      active_dropdown: false,
      active_state_dropdown: false,
      active_bank_dropdown: false,
      active_lga_dropdown: false,
      credit_card_select: true,
      cash_on_delivery_select: false,
      pos_on_delivery_select: false,
      acc_number: "",
      birthday: "",
      userAddress: "",
      payment_url: "",
      orderId: "",
    };
  },
  setup() {
    const productStore = useProductStore();
    const paymentStore = usePaymentStore();
    const userStore = useUserStore();

    return {
      productStore,
      paymentStore,
      userStore,
    };
  },
  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
  methods: {
    getCountryPhone() {
      this.country_selector_code = this.phone_code;
      //console.log(this.phone_menu['US'])

      /*for (var countries in this.phone_menu) {
                if (String(this.country_selector_code) == this.phone_menu[countries]) {

                }
            }*/
    },
    activateDropdown() {
      if (this.active_dropdown == false) {
        this.active_dropdown = true;
      } else {
        this.active_dropdown = false;
      }
    },
    activateStateDropdown() {
      if (
        this.active_state_dropdown === false ||
        this.active_state_dropdown === true
      ) {
        this.active_state_dropdown = !this.active_state_dropdown;
        console.log(this.active_state_dropdown);
      }
      //if (this.active_state_dropdown == false) {
      //    this.active_state_dropdown = true
      //}else {
      //    this.active_state_dropdown = false
      //}
    },
    activateBankDropdown() {
      if (
        this.active_bank_dropdown === false ||
        this.active_bank_dropdown === true
      ) {
        this.active_bank_dropdown = !this.active_bank_dropdown;
        console.log(this.active_bank_dropdown);
      }
    },
    activateLGAdropdown() {
      if (
        this.active_lga_dropdown === false ||
        this.active_lga_dropdown === true
      ) {
        this.active_lga_dropdown = !this.active_lga_dropdown;
        console.log(this.active_lga_dropdown);
      }
    },
    creditCardSelect() {
      if (this.credit_card_select == false) {
        this.credit_card_select = true;
        this.pos_on_delivery_select = false;
        this.cash_on_delivery_select = false;
      }
    },
    cashonDeliverySelect() {
      if (this.cash_on_delivery_select == false) {
        this.credit_card_select = false;
        this.pos_on_delivery_select = false;
        this.cash_on_delivery_select = true;
      }
    },
    posonDeliverySelect() {
      if (this.pos_on_delivery_select == false) {
        this.credit_card_select = false;
        this.pos_on_delivery_select = true;
        this.cash_on_delivery_select = false;
      }
    },
    toOrders() {
      this.$router.push("/orders");
    },
    reloadComponent() {
      this.$forceUpdate();
    },
    getStates() {
      toggleLoading(true);
      axios
        .get("/billing/states/")
        .then((response) => {
          toggleLoading(false);
          this.states_menu = response.data.states;
          console.log("States gotten:", this.states_menu);
        })
        .catch((error) => {
          toggleLoading(false);
          console.error("Error fetching states:", error);
        });
    },
    setBank(selectedBank: any, selectedBankCode: any) {
      this.selectedBank = selectedBank; // Set the selected state to the input field
      sessionStorage.setItem("BankC", selectedBankCode);
      this.active_bank_dropdown = false;
    },
    onInput() {
      this.active_state_dropdown = false;
      this.active_lga_dropdown = false;
    },
    setState(selectedState: any, selectedStateID: any) {
      this.delivery_state = selectedState; // Set the selected state to the input field
      this.delivery_state_id = selectedStateID;
      sessionStorage.setItem("State", selectedStateID);
      this.active_state_dropdown = false;
      this.delivery_lga = "";
      this.getLGAs();
    },
    getLGAs() {
      toggleLoading(true);
      const state_id = sessionStorage.getItem("State");
      axios
        .get(`/billing/states/${state_id}/`)
        .then((response) => {
          toggleLoading(false);
          this.lga_menu = response.data.lgas;
          console.log("LGAs gotten:", this.states_menu);
        })
        .catch((error) => {
          toggleLoading(false);
          console.error("Error fetching states:", error);
        });
    },
    setLGA(selectedLGA: any, selectedLGAID: any) {
      this.delivery_lga = selectedLGA; // Set the selected state to the input field
      this.delivery_lga_id = selectedLGAID;
      sessionStorage.setItem("LGA", selectedLGAID);
      this.active_lga_dropdown = false;
      this.getBilling();
    },
    getBilling() {
      toggleLoading(true);
      const lgaID = sessionStorage.getItem("LGA");
      const cartID = localStorage.getItem("cartID");

      const data = {
        lga_id: lgaID,
        cart_id: cartID,
      };

      axios
        .post(`/billing/billing-price/`, data)
        //axios({
        //    method: 'get',
        //    url: '/billing/billing-price/',
        //    data: {
        //        lga_id: lgaID,
        //        cart_id: cartID,
        //    },
        //})
        .then((response) => {
          toggleLoading(false);
          this.shipping_type = response.data.billing_total;
          this.sub_total = response.data.cart_grand_total;
          this.grandtotal = response.data.sum_total;
          console.log("Billing price returned", response);
        })
        .catch((error) => {
          toggleLoading(false);
          console.error("Error fetching total price:", error);
        });
    },
    async makePayment(e) {
      e.preventDefault();
    if (!this.userStore.getAuthHeader) {
      this.$router.push('/signin');
      return
    }
      toggleLoading(true);
      const shippingRes = await this.paymentStore.setShippingAddress({
        street_address: this.userAddress,
        lga: this.delivery_lga_id,
        state: this.delivery_state_id,
        phone: this.delivery_mobile_number,
        email: this.delivery_email_address,
      });
      const order = await this.productStore.createOrder()
      this.orderId = order.id
      const paymentRes = await this.paymentStore.makePayment({
        orderId: this.orderId,
      });
      // redirect to payment url
      toggleLoading(false);
      setTimeout(() => (window.location.href = paymentRes.data.link), 300);
    },
  },
  created() {
    this.getStates();
    document.title = "Checkout | Fondstore";
  },
  async mounted() {
   if (this.userStore.getUser.token) {
     toggleLoading(true);
    const cartDetails = await this.productStore.fetchCartItems();
    this.cartItems = cartDetails?.cart_items || [];
    toggleLoading(false);

    this.grandtotal =
      !!this.cartItems &&
      this.cartItems.reduce((acc: any, curr: any) => {
        return acc + curr.item.price * curr.quantity;
      }, 0);
  } else {
      this.$router.push('/signin');
      return
    }
  },
});
