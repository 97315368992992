
import { defineComponent } from 'vue';
import axios from 'axios'
import {Product} from '@/router/types'
import HelpCentreFAQ from '@/components/MenuSidebar/HelpCentre.vue'

export default defineComponent({
    name: 'Profile',
    components: {
        HelpCentreFAQ
    },
    data() {
        return {
            Profile: [] as Product[],
        }
    },
    methods: {
        getProfile() {
            axios.get('/manager/faqs/')
            .then(response => {
                this.Profile = response.data
            })
            .catch(error => {
                console.error('Error retrieving FAQs:', error)
            })
        },
    },
    mounted() {
        this.getProfile();
    },
})
