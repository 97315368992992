
import { defineComponent } from 'vue'
//import {PaystackButton} from 'paystack';
import paystack from 'paystack';
import axios from 'axios'
import { useProductStore } from '@/store/product';
import { useUserStore } from '@/store/user';

export default defineComponent({
    name: 'Shippingmode',
    data() {
        return {
            delivery_time: '20',
            store_pickup_price: 'FREE',
            home_delivery_price: '2.45',
            store_pickup_check: false,
            home_delivery_check: true,
            user_mail: '',
        }
    },
    props: {
        subtotal_price: String,
        shipping_type: String,
        total_price: String,
        store_pickup_check: Boolean,
        home_delivery_check: Boolean,
    },
    setup() {
        const productStore = useProductStore();
        const userStore = useUserStore()
        return {
            productStore,
            userStore,
        };
    },
    methods: {
        async getUserDetails() {
            const token = localStorage.getItem('AuthToken');

            try {
            const responseEmail = await axios.get('/auth/users/me', {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });     
            this.user_mail = responseEmail.data.email;
            console.log('User mail gotten:', this.user_mail)
            } catch (error) {
                console.error('There was an error getting the user email for Paystack:', error)
            }
        },
        initializePayment() {
//        if (this.total_price !== undefined && this.total_price !== null) {
//            
//        }
//        const amount = +this.total_price! * 100;
//
//        const paymentOptions = {
//            key: 'pk_test_3a1319eab46a31b488c3e5261e0700e2fd19d670', //my account public key
//            email: `${this.user_mail}`,
//            amount: amount, // Amount in kobo
//            currency: 'NGN', // Change to your currency
//            ref: 'unique-transaction-reference', // Generate a unique reference
//            callback: (response: any) => {
//            // Handle payment success
//            this.$router.push('/orders')
//            console.log(response);
//            },
//            onClose: () => {
//            // Handle payment close or failure
//            console.log('Payment closed.');
//            },
//        };
//
//        const paystack = PaystackButton(paymentOptions);
//        paystack.openIframe();
        },
        async makePayment() {
//            const paymentObject: any = {
//                key: 'pk_test_3a1319eab46a31b488c3e5261e0700e2fd19d670', //my account public key
//                email: this.user_mail,
//                amount: +this.total_price! * 100,
//                currency: 'NGN',
//                ref: 'unique-transaction-reference',
//            };
//            function payWithPaystack(paymentObject: any) {
//            return new Promise((resolve, reject) => {
//                paystack.inlinePay(paymentObject, (response: any) => {
//                if (response.status === 'successful') {
//                    resolve(response);
//                } else {
//                    reject(new Error('Payment failed'));
//                }
//                });
//            });
//            }
//                paystack.inlinePay(paymentObject, (response: any) => {
//                if (response.status === 'successful') {
//                    console.log(response);
//                } else {
//                    console.error('There is an error somewhere...')
//                }
//                });

//            try {
//                const response = await paystack.payWithPaystack(paymentObject);
//                console.log(response);
//            } catch (error) {
//                console.error('There is a payment error:', error)
//            }
        },
        toCheckout() {
            if (this.userStore.getAuthHeader) {
                this.$router.push('/checkout')
            } else {
                this.$router.push("/signin")
            }
        }
    },
    created() {
        this.getUserDetails();
//        paystack('pk_test_3a1319eab46a31b488c3e5261e0700e2fd19d670');
    },
})
