import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d66875a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "second-header-nav" }
const _hoisted_2 = { class: "header-nav-main-text-container" }
const _hoisted_3 = { class: "main-text font-class" }
const _hoisted_4 = { class: "header-nav-pages-link" }
const _hoisted_5 = { class: "home" }
const _hoisted_6 = { class: "arrow-container" }
const _hoisted_7 = { class: "home" }
const _hoisted_8 = { class: "arrow-container" }
const _hoisted_9 = { class: "product-page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_box_icon = _resolveComponent("box-icon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.mainf_header), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          class: "router-link",
          to: "/cart"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([{ 'bold': _ctx.mainf_header == 'Account', 'light': _ctx.mainf_header != 'Account' }, "home-link-text font-class"])
            }, "Cart", 2)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_box_icon, { name: "chevron-right" })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          class: "router-link",
          to: "/settings"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([{ 'bold': _ctx.mainf_header == 'Account', 'light': _ctx.mainf_header != 'Account' }, "home-link-text font-class"])
            }, "Account", 2)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_box_icon, { name: "chevron-right" })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, {
          class: "router-link",
          to: "/checkout"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([{ 'bold': _ctx.mainf_header == 'Checkout', 'light': _ctx.mainf_header != 'Checkout' }, "prodpage-link-text font-class"])
            }, "Payment", 2)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}