
import { defineComponent } from 'vue';
import axios from 'axios'
import {Product} from '@/router/types'
import HelpCentreFAQ from '@/components/MenuSidebar/HelpCentre.vue'

export default defineComponent({
    name: 'Network',
    components: {
        HelpCentreFAQ
    },
    data() {
        return {
            Network: [] as Product[],
        }
    },
    methods: {
        getNetwork() {
            axios.get('/manager/faqs/')
            .then(response => {
                this.Network = response.data
            })
            .catch(error => {
                console.error('Error retrieving FAQs:', error)
            })
        },
    },
    mounted() {
        this.getNetwork();
    },
})
