
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SecondHeader',
    props: {
        main_header: String,
        product_gender: String,
        bold_link: String,
        id: String,
    },
})
