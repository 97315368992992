
import { defineComponent} from 'vue'
import { useRoute } from 'vue-router';
import axios from 'axios';
import { Product, Section, Category } from '../router/types';
import FilterComponentVue from '../components/ProductCategoryComponent/FilterComponent.vue';
import MainHeaderVue from '../components/ProductCategoryComponent/MainHeader.vue';
import ItemComponentVue from '../components/ProductCategoryComponent/ItemComponent.vue'
import { toggleLoading } from '@/event-bus';

export default defineComponent({
    name: 'product.vue',
    components: {
        MainHeaderVue,
        FilterComponentVue,
        ItemComponentVue,
    },
    data() {
        return {
            list_header: [] as string[],
            page_choice: '' as string,
            products: [] as Product[],
            selectedItem: '',
            sections: [] as Section[],
            body_description: 'No description, returned yet' as string,
        }
    },
    mounted() {
//        this.fetchProducts();
//        this.fetchSpecificProducts();
//        this.selectedItem = this.list_header[0];
//        console.log(this.list_header[0])
//        console.log(this.selectedItem)
        document.title = `${this.page_choice} | Fondstore`
    },
    computed: {
        selectedSectiona() {
            return this.$route.params.section;
        },
    },
    methods: {
        getNameAndList() {
            toggleLoading(true);
            let selectedSection: string;

            if (Array.isArray(this.$route.params.section)) {
                selectedSection = this.$route.params.section.join(',');
            } else {
                selectedSection = this.$route.params.section;
            }
            this.page_choice = selectedSection.charAt(0).toUpperCase() + selectedSection.slice(1);

            axios.get('/api/sections/')
            .then((response) => {
                this.sections = response.data;
                toggleLoading(false);
//                console.log('Successful!')

                if (Array.isArray(this.sections)) {
//                    console.log('this.sections is an array')

                    const selectedSec = this.sections.find(
                        (section) => section.name.toLowerCase() === this.selectedSectiona
                    );
                    this.body_description = selectedSec ? selectedSec.body : 'No description, this.body';
                    if (selectedSec) {
                        this.list_header = selectedSec.categories.map(
                            (category: {
                                name: string
                            }) => category.name
                        );
                    } else {
                        this.list_header = [];
                    }
                    this.selectedItem = this.list_header[0];
                    this.fetchSpecificProducts()
// for testing                    this.fetchProducts()
                } else {
                    console.log('Chai, this.sections is not an array')
                }
            })
            .catch((error) => {
                toggleLoading(false);
                console.error('Error fetching sections:', error);
            });
        },
        fetchProducts() {
            toggleLoading(true);
            axios
                .get('/api/products/')
                .then((response: { data: any; }) => {
                    this.products = response.data;
                    toggleLoading(false);
                })
                .catch((error: any) => {
                    toggleLoading(false);
                    console.log('Error displaying products:', error);
                })
        },
        setSelectedItem(item: string) {        
            this.selectedItem = item;
        },
        fetchSpecificProducts() {
            toggleLoading(true);
            axios
            .get(`/api/categories/`)
            .then((response: { data: any; }) => {
                toggleLoading(false);
                this.products = response.data.find((item: Product) => item.name === `${this.selectedItem}`).products;
            })
            .catch((error: any) => {
                toggleLoading(false);
                console.log('Error displaying products:', error);
            })
        }
    },
    created() {
        this.getNameAndList()
    },
    watch: {
        '$route.params.section'(newSection) {
            toggleLoading(true);
            let reNewSection = newSection.toLowerCase();

            toggleLoading(false);

            this.page_choice = newSection.charAt(0).toUpperCase() + newSection.slice(1);
            const selectedSec = this.sections.find(
                (section) => section.name.toLowerCase() === reNewSection
            );
//            console.log(this.page_choice);
            toggleLoading(false);
            document.title = `${this.page_choice} | Fondstore`
            if (selectedSec) {
            this.list_header = selectedSec.categories.map(
                (category: {
                    name: string
                }) => category.name
            );
            } else {
                this.list_header = [];
            }
            toggleLoading(false);
            this.body_description = selectedSec ? selectedSec.body : 'No description, watch';
            this.selectedItem = this.list_header[0];
            this.fetchSpecificProducts()
        },
    },
})
