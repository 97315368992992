
import DisplayProducts from "@/components/DisplayProducts.vue";
import { toggleLoading } from "@/event-bus";
import { Product } from "@/router/types";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Search",
  components: {
    DisplayProducts,
  },
  data() {
    return {
      state: "no_state",
      //            state: 'recent_popular_state',
      //            state: 'item_found',
      searchQuery: "",
      products: [] as Product[],
      searchQueries: [] as string[],
    };
  },
  methods: {
    firstCharacter() {
      if (this.searchQuery.length >= 1) {
        this.state = "recent_popular_state";
        if (typeof localStorage !== "undefined") {
          const storedQueries = localStorage.getItem("searchQueries");
          if (storedQueries) {
            this.searchQueries = JSON.parse(storedQueries);
          }
        } else {
          console.error("Local storage is not available in this browser.");
        }
      }
    },
    async saveSearchQuery() {
      if (typeof localStorage !== "undefined") {
        this.searchQueries.unshift(this.searchQuery);
        if (this.searchQueries.length > 5) {
          this.searchQueries.pop();
        }
        localStorage.setItem(
          "searchQueries",
          JSON.stringify(this.searchQueries)
        );
      } else {
        console.error("Local storage is not available in this browser.");
      }
      toggleLoading(true);

      try {
        const response = await axios.get("/api/item_search/", {
          params: {
            query: this.searchQuery,
          },
        });
        this.state = "item_found";
        this.products = response.data.results;
        if (this.products.length === 0) {
          this.state = "item_not_found";
        }
        toggleLoading(false);
      } catch (error) {
        toggleLoading(false);
        console.error("Error searching for products:", error);
        alert("Error");
      }
    },
  },
  mounted() {
    document.title = "Search | Fondstore";
  },
});
