
import axios from 'axios'
import AuthHeader from '@/components/AuthHeader.vue';
import { defineComponent } from 'vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default defineComponent({
    name: 'PasswordReset',
    components: {
        AuthHeader,
    },
    data() {
        return {
            status: 'setnewpassword',
            buttonValue: 'Set new password',
            buttonDisabled: false,
//            email: '',
            password: '',
            re_password: '',
            errors: {
//                email: this.email,
                password: this.password,
                re_password: this.re_password,
                detail: '',
            },
        }
    },
    methods: {
        formatErrorMessage(messages: Function | string) {
            if (Array.isArray(messages)) {
                return messages.join(' '); // Join array elements with a space
            }
            return messages; // Return empty string if no error message
        },
        async setNewPassword(e) {
            e.preventDefault();
            this.buttonValue = 'Setting new password'
            this.buttonDisabled = true;

            const userDetails = {
                token: this.$route.params.token,
                uid: this.$route.params.uid,
                new_password: this.password,
                re_new_password: this.re_password,
            }

            try {
                const response = await axios.post('/auth/users/reset_password_confirm/', userDetails);

                this.status = 'passwordsaved'
                const token = response.data.access;
                localStorage.setItem('AuthToken', token)
                //console.log('Login successful:', response.data);
                
            } catch (error) {
                this.buttonValue = 'Sign In'
                this.buttonDisabled = false;

                if (axios.isAxiosError(error)) {
                    toast.error(
                        error?.response?.data?.new_password?.[0] ||
                        // non_field_errors
                        error?.response?.data?.non_field_errors?.[0]
                    )

                    if (error.response && error.response.status === 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    }
                    
                    } else {
                    this.errors.detail = 'An error occured. Please try again'
                    console.error('Non-Axios error:', error);
                }
                console.error('Login failed:', error);
            }
        },
        signIn() {
            this.$router.push({name: 'home'})
        }
    }
})
