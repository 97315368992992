
import { defineComponent } from 'vue';
import axios from 'axios'
import {Product} from '@/router/types'
import HelpCentreFAQ from '@/components/MenuSidebar/HelpCentre.vue'
import Search from '@/components/HelpCentreComponents/Search.vue'
import Notifications from '@/components/HelpCentreComponents/Notifications.vue'
import Favorite from '@/components/HelpCentreComponents/Favorite.vue'
import Cart from '@/components/HelpCentreComponents/Cart.vue'
import Profile from '@/components/HelpCentreComponents/Profile.vue'
import Network from '@/components/HelpCentreComponents/Network.vue'
import { useHelpCenterStore } from '@/store/help-center';
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
    name: 'HelpCenter',
    components: {
        HelpCentreFAQ,
        Search,
        Notifications,
        Favorite,
        Cart,
        Profile,
        Network,
        Spinner,
    },
    data() {
        return {
            helpOpen: 'out',
            FAQs: [] as Product[],
            expanded: 'in',
            expandedFaq: 'out',
            expandedSearch: 'closed',
            text_expanded_one: 'Messages',
            text_expanded_two: 'Help',
//            main_text: 'Tell us how we can help',
//            submain_text: 'Our Crew of superheroes are standing by for service & support',
        }
    },
    setup() {
        const helpCenterStore = useHelpCenterStore();
        return {
            helpCenterStore,
        }
    },
    props: {
        text_expanded_one: String,
        text_expanded_two: String,
    },
    methods: {
        toggleExpanded() {
            if (this.expanded === 'in') {
                this.expanded = 'out'
            } else {
                this.expanded = 'in'
            }
//            this.expanded = !this.expanded;
        },
        toggleHelp() {
            if (this.expandedFaq === 'in' || 'out') {
                this.expandedFaq = 'help'
            }
//            this.helpOpen = !this.helpOpen
        },
        toggleExpandedFaq() {
            if (this.expandedFaq === 'in') {
                this.expandedFaq = 'out'
            } else {
                this.expandedFaq = 'in'
            }
//            this.expandedFaq = !this.expandedFaq;
            console.log(this.expandedFaq)
//            if (this.expandedFaq === 'in') {
//                this.main_text = "← Frequently asked questions"
//                this.submain_text = ''
//            } else  {
//                this.main_text = "Tell us how we can help"
//                this.submain_text = 'Our Crew of superheroes are standing by for service & support'
//            }
        },
        getFAQs() {
            axios.get('/manager/faqs/')
            .then(response => {
                this.FAQs = response.data
            })
            .catch(error => {
                console.error('Error retrieving FAQs:', error)
            })
        },
        setExpandedFaqFalse() {
            this.expandedFaq = 'out';
        },
        setHelpFalse() {
            if (this.expandedSearch === 'search' || 'notify' || 'favor' || 'cart' || 'profile' || 'network') {
                this.expandedSearch = 'closed'
            } else if (this.expandedFaq === 'help') {
                this.expandedFaq = 'out';
                console.log('this.expandedFaq')
            }
        },
        setToSearch() {
            if (this.expandedSearch !== 'search') {
                this.expandedSearch = 'search'
            }
        },
        setToNotify() {
            if (this.expandedSearch !== 'notify') {
                this.expandedSearch = 'notify'
            }
        },
        setToFavor() {
            if (this.expandedSearch !== 'favor') {
                this.expandedSearch = 'favor'
            }
        },
        setToCart() {
            if (this.expandedSearch !== 'cart') {
                this.expandedSearch = 'cart'
            }
        },
        setToProfile() {
            if (this.expandedSearch !== 'profile') {
                this.expandedSearch = 'profile'
            }
        },
        setToNetwork() {
            if (this.expandedSearch !== 'network') {
                this.expandedSearch = 'network'
            }
        },
        refreshPage() {
        window.location.reload(); // Reload the current page
        },
    },
    created() {
        this.toggleExpanded();
        document.title = 'Help Center | Fondstore'
    },
    async mounted() {
        this.getFAQs();
    },
})
