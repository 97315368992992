
import { defineComponent } from 'vue'
import 'boxicons'

export default defineComponent({
    name: 'FilterComponent',
    data() {
        return {
            expand_filter: false,
        }
    },
    methods: {
        expandFilter() {
            if (this.expand_filter == false) {
                this.expand_filter = true
            } else {
                this.expand_filter = false
            }
        }
    }
})
