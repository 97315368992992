
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Loading from "@/components/Loading.vue";
import { isLoading } from "@/event-bus";
import axios from "axios";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
    Loading,
  },
  data() {
    return {
      showHeader: true,
      //      isLoading: false,
    };
  },
  methods: {
    verifyToken() {
      const token = localStorage.getItem("AuthToken");
      const refreshToken = localStorage.getItem("ReToken");

      if (token) {
        axios
          .post("/auth/jwt/verify", {
            token: token,
          })
          .then((response) => {
            console.log(response);
            const answer = response.data;
          })
          .catch((error) => {
            console.error("Error verifying token:", error);
          });
      } else {
        console.log("No token");
      }
    },
  },
  created() {
    this.verifyToken();
    this.$router.beforeEach((to, from, next) => {
      this.showHeader = ![
        "/signin",
        "/signup",
        "/forgotpassword",
        "/core/password/reset/confirm",
      ].includes(to.path);
      next();
    });
    //    this.verifyToken();
  },
  setup() {
    // Use the isLoading variable in the setup function
    const isLoadingValue = ref(isLoading);

    // Watch for changes in the global loading state and update the local variable
    watch(isLoading, (newLoadingState) => {
      if (!newLoadingState) {
        document.body.classList.remove("no-scroll");
      } else {
        document.body.classList.add("no-scroll");
        window.scrollTo(0, 0);
      }
      isLoadingValue.value = newLoadingState;
    });

    return {
      isLoading: isLoadingValue,
    };
  },
});
