
import AuthHeader from "@/components/AuthHeader.vue";
import axios from "axios";
import { defineComponent } from "vue";
import { toast } from "vue3-toastify";

export default defineComponent({
  name: "ForgotPassword",
  components: {
    AuthHeader,
  },
  data() {
    return {
      status: "forgotpassword",
      buttonValue: "Send the link",
      buttonDisabled: false,
      email: "",
      password: "",
      errors: {
        email: this.email,
        detail: "",
      },
    };
  },
  methods: {
    formatErrorMessage(messages: Function | string) {
      if (Array.isArray(messages)) {
        return messages.join(" "); // Join array elements with a space
      }
      return messages; // Return empty string if no error message
    },
    async sendLink() {
      this.buttonValue = "Sending you the link...";
      this.buttonDisabled = true;

      const userDetails = {
        email: this.email,
        //                password: this.password
      };

      try {
        const response = await axios.post(
          "/auth/users/reset_password/",
          userDetails
        );

        this.status = "emailsent";
        //                const token = response.data.access;
        //                localStorage.setItem('AuthToken', token)
        //console.log('Login successful:', response.data);
        //                this.$router.push({name: 'home'})
      } catch (error) {
        this.buttonValue = "Send the link";
        this.buttonDisabled = false;

        if (axios.isAxiosError(error)) {
          toast.error(error.message || "An error occured. Please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 401) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 403) {
            this.errors = error.response.data;
          }
        } else {
          this.errors.detail = "An error occured. Please try again";
          console.error("Non-Axios error:", error);
        }
        console.error("Sending link failed:", error);
      }
    },
  },
});
